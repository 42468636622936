import './bootstrap';
// resources/js/app.js
import 'flowbite';
// import ApexCharts from 'apexcharts';
const uploadLabel = document.querySelector('.upload-label');
const imagesInput = document.getElementById('images');
const fileCount = document.getElementById('fileCount');

// Drag and drop functionality
uploadLabel.addEventListener('dragover', (e) => {
    e.preventDefault();
    uploadLabel.classList.add('dragging');
});

uploadLabel.addEventListener('dragleave', () => {
    uploadLabel.classList.remove('dragging');
});

uploadLabel.addEventListener('drop', (e) => {
    e.preventDefault();
    uploadLabel.classList.remove('dragging');
    imagesInput.files = e.dataTransfer.files;
    updateFileCount();
});
function updateFileCount() {
    let input = document.getElementById('images');
    let count = input.files.length;
    let fileCount = document.getElementById('fileCount');
    fileCount.textContent = count + " files selected";
}
document.addEventListener('DOMContentLoaded', function() {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    document.getElementById('uploadForm').addEventListener('submit', function(event) {
        event.preventDefault();
        let formData = new FormData(this);
        let progressBar = document.getElementById('progressBar');
        let errorMsg = document.getElementById('errorMsg');
        let imageList = document.getElementById('imageList');
        imageList.innerHTML = ''; // Clear previous images

        let uploadedImages = document.getElementById('uploadedImages');

        progressBar.classList.remove('hidden');
        errorMsg.textContent = '';

        axios.post('/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: function(progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                progressBar.children[0].style.width = percentCompleted + '%';
            }
        })
            .then(function(response) {
                const baseUrl = 'https://immich.cc';  // Basis-URL mit https
                progressBar.classList.add('hidden');
                progressBar.children[0].style.width = '0%';

                response.data.images.forEach(function(image) {
                    const fullUrl = baseUrl + image.url;
                    const embedCode = `<embed src="${fullUrl}" width="300" height="200" />`;

                    let div = document.createElement('div');
                    div.innerHTML = `
            <img src="${fullUrl}" alt="${fullUrl}" class="w-32 h-auto mb-2"/>
            <div class="bg-gray-700 p-2 rounded relative">
                <p class="text-sm text-gray-300">Embed Code:</p>
                <div class="relative">
                    <textarea id="embed-${image.url}" class="w-full bg-gray-800 text-gray-200 text-sm p-2 rounded" readonly>${embedCode}</textarea>
                    <button data-copy-to-clipboard-target="embed-${image.url}" data-tooltip-target="tooltip-copy-embed-${image.url}" class="absolute end-2 top-1/2 -translate-y-1/2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg p-2 inline-flex items-center justify-center copy-button">
                        <span id="default-icon">
                            <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                            </svg>
                        </span>
                        <span id="success-icon" class="hidden inline-flex items-center">
                            <svg class="w-3.5 h-3.5 text-blue-700 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </span>
                    </button>
                    <div id="tooltip-copy-embed-${image.url}" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        <span id="default-tooltip-message">Copy to clipboard</span>
                        <span id="success-tooltip-message" class="hidden">Copied!</span>
                        <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                </div>
                <p class="text-sm text-gray-300 mt-4">Direct Link:</p>
                <div class="relative">
                    <input id="link-${image.url}" type="text" class="w-full bg-gray-800 text-gray-200 text-sm p-2 rounded" value="${fullUrl}" readonly />
                    <button data-copy-to-clipboard-target="link-${image.url}" data-tooltip-target="tooltip-copy-link-${image.url}" class="absolute end-2 top-1/2 -translate-y-1/2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg p-2 inline-flex items-center justify-center copy-button">
                        <span id="default-icon">
                            <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                            </svg>
                        </span>
                        <span id="success-icon" class="hidden inline-flex items-center">
                            <svg class="w-3.5 h-3.5 text-blue-700 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </span>
                    </button>
                    <div id="tooltip-copy-link-${image.url}" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        <span id="default-tooltip-message">Copy to clipboard</span>
                        <span id="success-tooltip-message" class="hidden">Copied!</span>
                        <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                </div>
            </div>`;

                    imageList.appendChild(div);
                });


                // Add event listeners for copy buttons
                document.querySelectorAll('.copy-button').forEach(function(button) {
                    button.addEventListener('click', function() {
                        let targetId = button.getAttribute('data-copy-to-clipboard-target');
                        let targetElement = document.getElementById(targetId);
                        targetElement.select();
                        document.execCommand('copy');

                        // Show success icon and tooltip message
                        button.querySelector('#default-icon').classList.add('hidden');
                        button.querySelector('#success-icon').classList.remove('hidden');
                        let tooltip = document.getElementById(button.getAttribute('data-tooltip-target'));
                        tooltip.querySelector('#default-tooltip-message').classList.add('hidden');
                        tooltip.querySelector('#success-tooltip-message').classList.remove('hidden');

                        // Reset after a short delay
                        setTimeout(function() {
                            button.querySelector('#default-icon').classList.remove('hidden');
                            button.querySelector('#success-icon').classList.add('hidden');
                            tooltip.querySelector('#default-tooltip-message').classList.remove('hidden');
                            tooltip.querySelector('#success-tooltip-message').classList.add('hidden');
                        }, 2000);
                    });
                });

            })
            .catch(function(error) {
                progressBar.classList.add('hidden');
                progressBar.children[0].style.width = '0%';
                if (error.response && error.response.data.message) {
                    errorMsg.textContent = error.response.data.message;
                } else if (error.message) {
                    errorMsg.textContent = error.message;
                } else {
                    errorMsg.textContent = 'An error occurred while uploading images.';
                }
            });
    });
});


document.getElementById('openDrawer').addEventListener('click', function() {
    let drawer = document.getElementById('drawer');
    drawer.classList.remove('hidden');
});

document.getElementById('closeDrawer').addEventListener('click', function() {
    document.getElementById('drawer').classList.add('hidden');
});

document.getElementById('drawerOverlay').addEventListener('click', function() {
    document.getElementById('drawer').classList.add('hidden');
});

document.querySelectorAll('button[data-copy-to-clipboard-target]').forEach(button => {
    button.addEventListener('click', () => {
        const target = document.getElementById(button.getAttribute('data-copy-to-clipboard-target'));
        target.select();
        document.execCommand('copy');

        const tooltip = document.getElementById(button.getAttribute('data-tooltip-target'));
        const defaultIcon = button.querySelector('#default-icon');
        const successIcon = button.querySelector('#success-icon');
        const defaultTooltipMessage = tooltip.querySelector('#default-tooltip-message');
        const successTooltipMessage = tooltip.querySelector('#success-tooltip-message');

        defaultIcon.classList.add('hidden');
        successIcon.classList.remove('hidden');
        defaultTooltipMessage.classList.add('hidden');
        successTooltipMessage.classList.remove('hidden');

        setTimeout(() => {
            defaultIcon.classList.remove('hidden');
            successIcon.classList.add('hidden');
            defaultTooltipMessage.classList.remove('hidden');
            successTooltipMessage.classList.add('hidden');
        }, 2000);
    });
});

// Öffnen des Drawers
document.getElementById('openDrawer').addEventListener('click', function() {
    let drawer = document.getElementById('drawer');
    drawer.classList.remove('hidden');
});

// Schließen des Drawers
document.getElementById('closeDrawer').addEventListener('click', function() {
    document.getElementById('drawer').classList.add('hidden');
});

// Schließen des Drawers beim Klicken außerhalb
document.getElementById('drawerOverlay').addEventListener('click', function() {
    document.getElementById('drawer').classList.add('hidden');
});
